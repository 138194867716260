.Title {
  font-family: "Bowlby One SC", cursive;
  text-align: center;
  font-size: 60px;
}
.SubTitle {
  font-family: "Roboto Slab";
  text-align: center;
  font-size: 14px;
  transform: translateY(-10px);
}

@media (max-width: 600px) and (max-height: 750px) {
  .Title {
    margin-top: 10% !important;
    font-size: 30px;
  }
  .SubTitle {
    font-size: 10px;
    transform: translateY(-2px);
    width: 190px;
    align-self: center;
    margin-bottom: 20px;
    //transform: translateY(-10px);
  }
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 300px !important;
    padding: 5px;
    margin-top: 20px;
  }
  .BottomAppStoreMessage {
    font-size: 14px !important;
    bottom: 15px;
  }
}

@media (max-width: 600px) and (min-height: 750px) {
  .Title {
    margin-top: 10% !important;
    font-size: 30px;
  }
  .SubTitle {
    font-size: 10px;
    transform: translateY(-2px);
    width: 190px;
    align-self: center;
    //transform: translateY(-10px);
  }
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 300px !important;
    padding: 5px;
    margin-top: 40px;
  }
  .BottomAppStoreMessage {
    font-size: 14px !important;
    bottom: 15px;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 500px;
    padding: 5px;
    margin-top: 20px;
  }
}

.LandingImage {
  //height: 100px;
  //margin-top: 10px;
  //max-width: 90%;
  width: 700px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.BottomAppStoreMessage {
  font-family: "Roboto Slab";
  font-size: 30px;
  margin-top: 20px;
  align-self: center;
  text-align: center;
  bottom: 15px;
}

@media (max-height: 700px) and (min-width: 600px) {
  .BottomAppStoreMessage {
    // border-radius: 8px !important;
    // background-color: #444444 !important;
    // color: whitesmoke !important;
    // padding-left: 10px;
    // padding-right: 10px;
  }
}

.DetailsSectionTitle {
   //font-family: "Londrina Solid" cursive;
  //font-family: "Raleway", sans-serif;
  font-family: "Roboto Slab", serif;
  font-size: 25px;
  font-weight: bold;
  color: #444444;
  //color: #2c5986;
  margin-bottom: 20px;
  margin-top: 60px;
  text-align: center;
}

.ResponsiveRow {
  align-items: center;
  justify-content: center;
  //justify-content: space-around;
  // padding-top: 100px;
}