.CenteredLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Logo {
  height: 100%;
  width: auto;
}

@media (max-width: 768px) {
  .Logo {
    width: 80%;
    height: auto;
  }
}

.SmallLogo {
  transform: translateY(-100%);
}

.LogoAppear {
  width: 100%;
  height: auto;
  transition: all 1000ms;
}
.LogoAppearActive {
  width: 50%;
}
.LogoAppearDone {
  width: 50%;
}
.LogoEnter {
  width: 100%;
  height: auto;
}
.LogoEnterActive {
  width: 30px;
  transition: all 1000ms;
}
.LogoEnterDone {
  width: 50%;
}
.LogoExit {
  width: 100%;
  height: auto;
}
.LogoExitActive {
  width: 100%;
  height: auto;
}
.LogoExitDone {
  width: 100%;
  height: auto;
}
