.Content {
  display: flex;
  flex-direction: row;
}

.Picture {
  flex-grow: 1;
  //height: 100%;
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 5;
  //margin: auto;
  //padding-right: 15px;
  //height: auto;
  //width: auto;
  //border-radius: 50%;
}

.Text {
  flex-grow: 1;
  //width: 50%;
}

.ResponsiveCol {
  text-align: center;
}

.ResponsiveRow {
  align-items: center;
  //justify-content: space-around;
  // padding-top: 100px;
}

@media (max-width: 768px) {
  .ResponsiveRow {
    padding-top: 30px;
  }
}

.LogoImage {
  //height: 100px;
  //margin-top: 10px;
  width: 90%;
  width: 400px;
  padding: 5px;
  text-align: center;
  //margin-right: 10px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
}

.CaseStudyCard {
  // border-style: solid;
  // border-width: 1;
}

.CaseStudyCard:hover {
  cursor: pointer;
  opacity: 0.7;
}

.SectionTitle {
  //font-family: "Londrina Solid" cursive;
  //font-family: "Raleway", sans-serif;
  font-family: "Roboto Slab", serif;
  font-size: 40px;
  font-weight: normal;
  //color: #444444;
  margin-bottom: 10px;
}
