@import "../../themes.scss";

.Title {
  font-family: "Bowlby One SC", cursive;
  text-align: center;
  font-size: 60px;
}
.SubTitle {
  font-family: "Roboto Slab";
  text-align: center;
  font-size: 14px;
  transform: translateY(-10px);
}

@media (max-width: 600px) and (max-height: 750px) {
  .Title {
    margin-top: 10% !important;
    font-size: 30px;
  }
  .SubTitle {
    font-size: 10px;
    transform: translateY(-2px);
    width: 190px;
    align-self: center;
    margin-bottom: 20px;
    //transform: translateY(-10px);
  }
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 300px !important;
    padding: 5px;
    margin-top: 20px;
  }
  .BottomAppStoreMessage {
    font-size: 14px !important;
    bottom: 15px;
  }
}

@media (max-width: 600px) and (min-height: 750px) {
  .Title {
    margin-top: 10% !important;
    font-size: 30px;
  }
  .SubTitle {
    font-size: 10px;
    transform: translateY(-2px);
    width: 190px;
    align-self: center;
    //transform: translateY(-10px);
  }
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 300px !important;
    padding: 5px;
    margin-top: 40px;
  }
  .BottomAppStoreMessage {
    font-size: 14px !important;
    bottom: 15px;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .LandingImage {
    //height: 100px;
    //margin-top: 10px;
    //max-width: 90%;
    width: 500px;
    padding: 5px;
    margin-top: 20px;
  }
}

.LandingImage {
  //height: 100px;
  //margin-top: 10px;
  //max-width: 90%;
  width: 700px;
  //padding: 20px;
  //padding-left: 20px;
  //padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  margin: auto;
  //border-radius: 10px;
}

.BottomAppStoreMessage {
  font-family: "Roboto Slab";
  font-size: 30px;
  margin-top: 20px;
  align-self: center;
  text-align: center;
  bottom: 15px;
}

.LandingWrapper {
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #e5e5f5;
  //padding-top: 15px;
  //padding-bottom: 15px;
  margin-top: 55px;
  margin-bottom: 35px;
  box-shadow: 0 0 35px 35px #e5e5f5;
}

@media (max-height: 700px) and (min-width: 600px) {
  .BottomAppStoreMessage {
    // border-radius: 8px !important;
    // background-color: #444444 !important;
    // color: whitesmoke !important;
    // padding-left: 10px;
    // padding-right: 10px;
  }
}

.DetailsSectionTitle {
   //font-family: "Londrina Solid" cursive;
  //font-family: "Raleway", sans-serif;
  font-family: "Roboto Slab", serif;
  font-size: 25px;
  font-weight: bold;
  color: #444444;
  //color: #2c5986;
  margin-bottom: 20px;
  margin-top: 60px;
  text-align: center;
}

.ResponsiveRow {
  align-items: center;
  justify-content: center;
  //justify-content: space-around;
  // padding-top: 100px;
}

.FullScreenMessage {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: $mainColor;
  //background-color: $successColor;
  //background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 1rem;
  transition: height 400ms;
  display: flex;
  transition: all 1s;
  margin-bottom: 10px;
  // border-width: 1px !important;
  // border-color: $mainColor !important;
  // border: solid;
}

@media (max-width: 768px) {
  .Subtitle {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.Subtitle {
  margin-bottom: 2px;
  color: grey;
  font-family: $workingFont;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
}

.ErrorText {
  color: black !important;
}

.ErrorIcon {
  color: $errorColor !important;
  opacity: 0.9;
}

.FailedMessage {
  background-color: $errorColor;
  font-family: $inputFont;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 1px;
  width: 240px;
}

.IconContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
  color: $successColor;
}