@import "~bootstrap/scss/bootstrap";

// .masthead {
//   height: 100vh;
//   width: 100vw;
//   min-height: 100px;
//   background-image: url("./assets/images/background_for_logo.jpg");
//   background-size: cover;
//   background-position: top left;
// }

.masthead {
  height: 100vh;
  width: 100%;
  min-height: 100px;
  //background-image: url("./assets/images/background_for_logo.jpg");
  //background-size: cover;
  //background-position: top left;
  // position: -webkit-sticky;
  // position: sticky;
  // bottom: 50px;
}

// .site-background {
//   //height: 100vh;
//   width: 100%;
//   //min-height: 100px;
//   background-image: url("./assets/images/background_for_logo.jpg");
//   background-size: cover;
//   //background-position: center;
//   //position: fixed;
//   //background-attachment: fixed;
// }

.site-background {
  height: 100vh;
  width: 100%;
  //min-height: 100px;
  //background-image: url("./assets/images/background_for_logo.jpg");
  // background: linear-gradient(
  //   0deg,
  //   rgba(82, 100, 144, 1) 0%,
  //   rgba(127, 198, 213, 1) 100%
  // );

  // background: radial-gradient(
  //   circle,
  //   rgba(82, 100, 144, 1) 0%,
  //   rgba(144, 237, 240, 1) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(82, 100, 144, 1) 0%,
    rgba(135, 215, 218, 1) 100%
  );
  //overflow-x: hidden !important;
  max-width: 100% !important;
  background-size: cover;
  //background-position: center;
  //position: fixed;
  //background-attachment: fixed;
  position: -webkit-sticky;
  position: sticky;
  //top: -92vh;
  top: calc(-100vh + 60px);
}

.site-background-container {
  //height: 100vh;
  width: 100%;
  //background-position: center;
  //position: fixed;
  //background-attachment: fixed;
  //display: flex;
  //align-items: flex-start;
}

@media (max-width: 768px) {
  .masthead {
    height: 100vh;
    width: 100%;
  }
}

.logo-shrink-transition-enter {
  /*We give the list the initial dimension of the list button*/
  width: 100px;
  height: 100px;
}
/* This is where we can add the transition*/
.logo-shrink-transition-enter-active {
  width: 50px;
  height: 50px;
  transition: all 900ms;
}

.navbar-custom {
  //background-color: #025596;
  //background-color: #f2f4f7;
  max-width: 100vw !important;
}
