.Content {
  display: flex;
  flex-direction: row;
}

.Picture {
  flex-grow: 1;
  //height: 100%;
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 5;
  //margin: auto;
  //padding-right: 15px;
  //height: auto;
  //width: auto;
  //border-radius: 50%;
}

.Text {
  flex-grow: 1;
  //width: 50%;
}

.ResponsiveCol {
  text-align: center;
}

.ResponsiveRow {
  align-items: center;
  padding-top: 100px;
}

@media (max-width: 768px) {
  .ResponsiveRow {
    padding-top: 60px;
  }
}
