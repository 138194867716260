.NavLink {
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  //background-color: #025596;
  font-weight: bold;
}

.NavLink:hover {
  //font-weight: bold;
}

.ActiveLink {
  // border-radius: 10px;
  // border-width: thin;
  // border-color: white !important;
  // border: solid;
  // box-sizing: border-box !important;
}

.NavItemContainer {
  // margin-left: 5px;
  // margin-right: 5px;
}
